/*
 *   File : processing-sip.js
 *   Author : https://evoqins.com
 *   Description : SIP List
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";

// Custom components
import { DataTableContainer } from "../../../Components/DataTable";
import { EmptyScreen } from "../../../Components/Others";
import { Badge } from "../../../Components/Badge";

// Custom styles
import Colors from '../../../Styles/color.module.scss';

// Services
import APIService from "../../../Services/api-service";
import Config from "../../../Helper/config";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "18px",
            paddingTop: "0px",
            paddingBottom: '0px',
            minHeight: "32px"
        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px"
        }
    }
};


const ProcessedSIPList = (props) => {

    const [pageLoader, setPageLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [sipList, setSipList] = useState([]);
    const [sipData, setSipData] = useState({});

    useEffect(() => {
        _sipList();
        //eslint-disable-next-line
    }, [currentPage]);

    function _handlePageChange(page) {
        setCurrentPage(page);
        setPageLoader(true);
        window.scrollTo(0, 0);
    };

    const SIP_COLUMNS = [
        {
            name: "Name",
            cell: row => <div className="d-flex align-items-center">
                <span className="ps-2">{row.name}</span>
            </div>,
            width: "350px"
        },
        {
            name: "Amount",
            width: "140px",
            cell: row => row.amount
        },
        {
            name: "Executed date",
            width: "140px",
            cell: row => row.executed_date ? row.executed_date : "-"
        },
        {
            name: "Status",
            width: "140px",
            cell: row => <Badge status={row.status} color={row.status === "Success" ? Config.BADGE_COLORS.green : row.status === "Failed" ? Config.BADGE_COLORS.red : Config.BADGE_COLORS.orange} />
        },

    ]

    // API - SIP list 
    const _sipList = () => {
        const url = "/user/sip/processed-list";

        const request = {
            user_id: props.id,
            page_num: currentPage,
            page_size: 10,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setSipList(response.data.sips);
                setSipData(response.data);
            } else {
                setSipList([]);
            }
            setPageLoader(false);
        })
    }

    return (
        <Fragment>
            <div className="bg-white border-radius-24px padding-32px-tb">
                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4 padding-32px-lr">
                    Processing SIP
                </h4>
                {
                    pageLoader === true ?
                        <div className="h-80vh d-flex align-items-center justify-content-center">
                            <img src={require("../../../Assets/Images/Loader/page-loader.gif")}
                                alt={"loader"}
                                width={60}
                                height={60}
                            />
                        </div>
                        :
                        sipList.length === 0 ?
                            <EmptyScreen className="my-5"
                                title="No SIPs found"
                                image={require("../../../Assets/Images/Empty/no-data.png")}
                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                            :
                            <div className="pt-4 position-relative">
                                <DataTableContainer data={sipList}
                                    columns={SIP_COLUMNS}
                                    customStyles={TABLE_STYLE}
                                    pagination={true}
                                    paginationServer={true}
                                    perPage={12}
                                    defaultSortAsc={false}
                                    onChangePage={_handlePageChange}
                                    paginationTotalRows={sipData.total_count}
                                    paginationDefaultPage={sipData.current_page}
                                    paginationComponentOptions={{ noRowsPerPage: true }} />
                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-32px-lr position-absolute color-jett-black bottom-10px">Showing {sipData.record} of {sipData.total_count} entries</span>
                            </div>
                }
            </div>
        </Fragment>
    )
}

export default ProcessedSIPList;