/*
 *   File : link.js
 *   Author : https://evoqins.com
 *   Description : UI component to enable submission in the platform.
 *   Version : 1.0.0
*/

const LinkButton = (props) => {
    return (
        <p className={`${props.className} e-montserrat-semi-bold e-font-14 e-line-height-20 color-navy-blue mb-0 border-bottom-1px text-nowrap cursor-pointer w-max-content`}
            onClick={() => props.handleClick()}>{props.text}</p>
    )
}

export default LinkButton