/*
 *   File : banners.js
 *   Author URI : www.evoqins.com
 *   Description : Container screen to list available Banners.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

// Custom components
import { PrimaryButton } from "../../Components/Buttons";
import { ListBanners } from ".";
import { ManageBannerModal } from "../../Components/Modal";

// import services
import APIService from "../../Services/api-service";
import { CustomTitle } from "../../Components/Title";


const Banners = () => {

    const [bannerDetail, setBannerDetail] = useState(null);
    const [openBannerModal, setOpenBannerModal] = useState(false);

    const [pageLoader, setPageLoader] = useState(true);
    const [bannerList, setBannerList] = useState([]);



    useEffect(() => {
        _getBannerList();
    }, []);


    useEffect(() => {
        if (openBannerModal === true) {
            const modal = new Modal(document.getElementById("manage-banner"));
            modal.show();
        }
    }, [openBannerModal])


    function _handleBannerModal(status, detail) {
        setOpenBannerModal(status);
        setBannerDetail(detail);
    }

    // API - banner list
    const _getBannerList = () => {
        const url = "/banner/get";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBannerList(response.data);
            } else {
                setBannerList([])
            }
            setPageLoader(false);
        })
    }



    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr d-flex justify-content-between">
                <CustomTitle title="Manage Banners" type={1} />
                <PrimaryButton
                    label="Add banner"
                    className="padding-10px-tb px-4 h-max-content"
                    handleClick={() => _handleBannerModal(true, null)} />
            </div>

            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="e-bg-white border-radius-32px pt-4 padding-40px-bottom">

                            {
                                pageLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    <ListBanners bannerList={bannerList}
                                        handleEdit={(row) => _handleBannerModal(true, row)}
                                        updateList={_getBannerList} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                openBannerModal === true && <ManageBannerModal
                    banner={bannerDetail}
                    closeModal={() => _handleBannerModal(false, null)}
                    bannerUpdated={() => {
                        _getBannerList();
                        _handleBannerModal(false, null)
                    }} />
            }
        </Fragment >

    )
}

export default Banners