/*
 *   File : data-table.js
 *   Author : https://evoqins.com
 *   Description : Data table component
 *   Integrations : react-data-table-component
 *   Version : 1
 */

/*import packages*/
import React, { memo } from 'react';
import DataTable from 'react-data-table-component';

const SORT_ICON = (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='ms-1'>
    <path d="M3.86193 2.86177C4.12228 2.60142 4.54439 2.60142 4.80474 2.86177L7.4714 5.52843C7.73175 5.78878 7.73175 6.21089 7.4714 6.47124C7.21106 6.73159 6.78895 6.73159 6.5286 6.47124L5 4.94265V12.6665C5 13.0347 4.70152 13.3332 4.33333 13.3332C3.96514 13.3332 3.66667 13.0347 3.66667 12.6665V4.94265L2.13807 6.47124C1.87772 6.73159 1.45561 6.73159 1.19526 6.47124C0.934913 6.21089 0.934913 5.78878 1.19526 5.52843L3.86193 2.86177Z" fill="#145da0" />
    <path d="M11.4711 13.138C11.2107 13.3983 10.7886 13.3983 10.5283 13.138L7.8616 10.4713C7.60125 10.211 7.60125 9.78886 7.8616 9.52851C8.12195 9.26816 8.54406 9.26816 8.80441 9.52851L10.333 11.0571L10.333 3.33325C10.333 2.96506 10.6315 2.66659 10.9997 2.66659C11.3679 2.66659 11.6663 2.96506 11.6663 3.33325L11.6663 11.0571L13.1949 9.52851C13.4553 9.26816 13.8774 9.26816 14.1377 9.52851C14.3981 9.78886 14.3981 10.211 14.1377 10.4713L11.4711 13.138Z" fill="#6D6D6D" />
</svg>
);

// custom checkbox
const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
        <div className="position-relative me-3">
            <input
                htmlFor="booty-check"
                type="checkbox"
                className="form-check-input w-16px h-16px border-color-gray border-2px cursor-pointer "
                ref={ref}
                onClick={onClick}
                {...rest} />
        </div>
    )
})

const DataTableContainer = (props) => {

    return (
        <div className='e-table'>
            <DataTable columns={props.columns}
                data={props.data}
                customStyles={props.customStyles}
                paginationPerPage={props.perPage}
                onChangePage={props.onChangePage}
                paginationDefaultPage={props.paginationDefaultPage}
                paginationTotalRows={props.paginationTotalRows}
                pagination={props.pagination}
                selectableRows={props.selectableRows}
                paginationServer={props.paginationServer}
                paginationIconPrevious={props.paginationIconPrevious}
                paginationIconNext={props.paginationIconNext}
                onRowClicked={(e) => props.rowClick(e)}
                onSelectedRowsChange={(allSelected, selectedCount, selectedRows) => props.selectRows(allSelected, selectedCount, selectedRows)}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
                paginationComponentOptions={props.paginationComponentOptions}
                paginationIconLastPage={props.paginationIconLastPage}
                paginationIconFirstPage={props.paginationIconFirstPage}
                sortIcon={SORT_ICON}
                defaultSortAsc={props.defaultSortAsc}
                defaultSortFieldId={props.defaultSortFieldId}
                onRowMouseEnter={props.onRowMouseEnter}
                onRowMouseLeave={props.onRowMouseLeave}
                fixedHeader={props.fixedHeader}
                selectableRowsComponent={Checkbox}
                fixedHeaderScrollHeight={props.fixedHeaderScrollHeight} />
        </div>
    );
}

DataTableContainer.defaultProps = {
    rowClick: () => { },
    perPage: 10,
    selectRows: () => { },
    // defaultSortAsc: false
}

export default memo(DataTableContainer)


