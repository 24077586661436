/*
 *   File : detail.js
 *   Author : https://evoqins.com
 *   Description : Customer Detail
 *   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Custom components
import { UserDetail } from "../../Components/Cards";
import { CustomTabBar } from "../../Components/Tab";
import { GoalInvestments, NonGoalInvestments, OrdersList, Profile, SIPList, ProcessedSIPList } from "./Helper";
import { Breadcrumb } from "../../Components/Others";

// Helper
import { _forceNavigate } from "../../Helper/helper";
import APIService from "../../Services/api-service";

// Custom styles

const TAB_DATA = [
    {
        id: 1,
        label: "Profile & KYC"
    },
    {
        id: 2,
        label: "Orders"
    },
    {
        id: 3,
        label: "SIP"
    },
    {
        id: 4,
        label: "Processing SIP"
    },

    {
        id: 7,
        label: "Goal Summary"
    },
    {
        id: 8,
        label: "Investment without goals"
    },
]


const CustomerDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState(1);
    const [loader, setLoader] = useState(true);
    const [profileData, setProfileData] = useState(null);
    const [historyData, setHistoryData] = useState(null);

    // When any tab is selected on press of back or chrome navigation navigate to main container
    _forceNavigate(() => {
        navigate("/customer", {
            state: historyData
        })
    });

    useEffect(() => {
        if (location.state !== null) {

            if (location.state.history === "customer") {
                setHistoryData(location.state);
            }

            else {
                setTabIndex(location.state.tab_index);
            }
        }
    }, [location]);

    useEffect(() => {
        setLoader(true);
        _getUserDetail();
        // eslint-disable-next-line
    }, [])

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        // navigate("/customer", {
        //     state: historyData
        // })
        setTimeout(() => {
            navigate('/customer/detail', { state: { tab_index: tab_index, pageNumber: 1, id: location.state.id, historyData } })
        }, 0);
    }

    function _handleNavigate() {
        navigate("/customer", {
            state: historyData
        })
    }

    // API - get user detail
    const _getUserDetail = () => {
        const url = "/user/detail";
        const request = {
            user_id: location.state.id,
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setProfileData(response.data);
                setLoader(false);
            }
            else {
                _handleNavigate();
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    return (
        loader === true ?
            <div className="h-80vh d-flex align-items-center justify-content-center">
                <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                    alt={"loader"}
                    width={60}
                    height={60}
                />
            </div>
            :
            <Fragment>
                {/* breadcrumb navigation */}
                <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                    <div className="padding-110px-bottom">
                        <Breadcrumb handleNavigate={_handleNavigate}
                            mainTitle="Customer summary"
                            subTitle={profileData.overview.name} />
                    </div>
                </div>

                <div className="e-body-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className="bg-white border-radius-16px py-4 padding-32px-lr">
                                <UserDetail data={profileData} />
                            </div>
                            <div className="margin-32px-top mb-4">
                                <CustomTabBar data={TAB_DATA}
                                    selectedTab={tabIndex}
                                    onSelectTab={_handleTab} />


                            </div>
                            {
                                tabIndex === 1 ?
                                    // console.log('fsd', tabIndex)
                                    <Profile data={profileData} />
                                    :
                                    tabIndex === 2 ?
                                        <OrdersList id={location.state.id} />
                                        :
                                        tabIndex === 3 ?
                                            <SIPList id={location.state.id} />
                                            :
                                            tabIndex === 4 ?
                                                <ProcessedSIPList id={location.state.id} />
                                                :
                                                tabIndex === 7 ?
                                                    <GoalInvestments id={location.state.id} />
                                                    :
                                                    tabIndex === 8 ?
                                                        <NonGoalInvestments id={location.state.id} />
                                                        :

                                                        null
                            }
                        </div>
                    </div>
                </div>

            </Fragment>
    )
}

export default CustomerDetail;