/*
 *   File : list-banners.js
 *   Author URI : www.evoqins.com
 *   Description : Container screen to list available banners.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// Custom components
import { TextButton } from "../../Components/Buttons";
import { DataTableContainer } from "../../Components/DataTable";
import { ConfirmDeleteModal } from "../../Components/Modal";
import { EmptyScreen } from "../../Components/Others";

// import styles
import Colors from "../../Styles/color.module.scss";

// Services
import APIService from "../../Services/api-service";

// // Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "18px",
            paddingTop: "0px",
            paddingBottom: '0px',
            minHeight: "32px"
        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px"
        }
    }
};

const CONTENT_TYPES = [
    {
        label: "Video",
        value: 1
    },
    {
        label: "Blog",
        value: 2
    },
    {
        label: "Default",
        value: 3
    }
]

const BANNER_TYPES = [
    {
        label: "Banner 1",
        value: 1
    },
    {
        label: "Banner 2",
        value: 2
    },
    {
        label: "Banner 3",
        value: 3
    },
    {
        label: "Banner 4",
        value: 4
    }
]

const ListBanners = (props) => {
    const [bannerId, setBannerId] = useState(null);

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        if (bannerId !== null) {
            const modal = new Modal(document.getElementById("confirm-delete"));
            modal.show();
        }
    }, [bannerId]);

    function _getContentType(id) {
        const banner_type = CONTENT_TYPES.findIndex((item) => item.value === id);
        return CONTENT_TYPES[banner_type].label
    }

    function _getBannerType(id) {
        const banner_type = BANNER_TYPES.findIndex((item) => item.value === id);
        return BANNER_TYPES[banner_type].label
    }
    const BANNER_COLUMN = [
        {
            name: "Image ",
            width: '220px',
            cell: row => <img src={row.image_url} alt={row.banner_type}
                width={150}
                height={80}
                className="object-fit-cover border-radius-8px" />,
        },
        {
            name: "Type",
            cell: row => _getBannerType(row.banner_id)
        },
        {
            name: "Banner",
            cell: row => _getContentType(row.content_type)
        },
        {
            name: '',
            sortable: false,
            width: "150px",
            cell: row => <div className="d-flex gap-16px">

                <TextButton
                    className="e-font-14 e-line-height-24"
                    name="Edit" hide_arrow={true}
                    onPress={() => props.handleEdit(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24"
                    name="Delete" hide_arrow={true}
                    onPress={() => _openDeleteModal(row.banner_id)} />

                {/* <TextButton
                    className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                    onPress={() => _handleEdit(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24" name="Delete" hide_arrow={true}
                    onPress={() => _openDeleteModal(row)} /> */}
            </div>
        }
    ]


    function _openDeleteModal(id) {
        setBannerId(id);
    }

    // API - delete banner
    const _deleteBanner = async () => {
        let status = false
        setApiLoader(true);
        const url = "/banner/delete"
        const request = {
            banner_id: bannerId
        }
        await APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss()
                toast.success("Banner deleted", {
                    type: 'success'
                })
                status = true;

            } else {
                status = false;
            }
            setApiLoader(false);
        })
        return status
    }
    return (
        <Fragment>


            {
                props.bannerList.length === 0 ?
                    <EmptyScreen className="my-5"
                        title="No banners found"
                        image={require("../../Assets/Images/Empty/no-data.png")}
                        description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                    :
                    <DataTableContainer data={props.bannerList}
                        columns={BANNER_COLUMN}
                        customStyles={TABLE_STYLE} />}

            {
                bannerId !== null && <ConfirmDeleteModal title="Delete banner image"
                    content="Are you sure you want to delete the Mobile app home page banner image?"
                    closeModal={() => _openDeleteModal(null)}
                    delete={_deleteBanner}
                    loader={apiLoader}
                    updateList={() => {
                        props.updateList();
                        _openDeleteModal(null)
                    }} />
            }
        </Fragment>
    )
}

export default ListBanners;