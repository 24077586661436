/*
 *   File : manage-asset-class.js
 *   Author URI : https://evoqins.com
 *   Description : add and update asset class modal 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";

// Custom components
import { Icon } from "../Icon";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomTextInput1 } from "../FormElements";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";
import APIService from "../../Services/api-service";
import { toast } from "react-toastify";

// Helper


const ManageAssetClass = (props) => {
    const [asset, setAsset] = useState(props.asset);
    const [assetError, setAssetError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("asset-class");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    function _handleAsset(input_value) {
        setAsset(input_value);
        setAssetError("");
    }



    function _handleSubmitForm() {
        let valid = true;

        if (asset === "") {
            valid = false;
            setAssetError("Asset Class is required");
        }

        if (valid === true) {
            _editAsset()
        }
    }


    // API - edit asset
    const _editAsset = () => {
        setApiLoader(true);
        const url = "/fund/edit-asset-class";
        const request = {
            "allocation_ids": props.fundIds.map((item) => item.id),
            "assetclass": asset
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success("Asset class updated", {
                    type: "success"
                });
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.updateList()
            } else {
                toast.error(response.message, {
                    type: "error"
                })
            }
            setApiLoader(false);
        });
    }
    return (
        <div className={`modal fade ${style.e_modal_wrapper}`}
            id="asset-class"
            tabIndex="-1"
            aria-labelledby="asset-class"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                            {props.type === 1 ? "Edit registered email" : "Edit contact number"}
                        </h5>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer' />
                        <span id="close" data-bs-dismiss="modal" className="d-none"></span>
                    </div>
                    <div className="row padding-32px-top">


                        <div className="col-12">
                            <CustomTextInput1 label="Asset Class"
                                type="text"
                                postfix={"*"}
                                error={assetError}
                                value={asset}
                                placeHolder="Asset class"
                                handleChange={_handleAsset} />
                        </div>

                    </div>
                    <div className="d-flex justify-content-end margin-40px-top gap-8px">
                        <SecondaryButton label="Cancel"
                            className="padding-14px-tb padding-50px-lr"
                            cancel="modal" />
                        <PrimaryButton label={"Update"}
                            className="padding-14px-tb px-4"
                            disabled={apiLoader}
                            loader={apiLoader}
                            handleClick={_handleSubmitForm} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageAssetClass;