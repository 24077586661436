/*
 *   File : profile.js
 *   Author : https://evoqins.com
 *   Description : Profile summary
 *   Version : 1.0.0
*/

import { Fragment } from "react"

const Profile = (props) => {

    const ProfileInfo = ({ title, description }) => {
        return (
            <div className="col-lg-4 col-sm-6 col-12">
                <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                    {title}
                </h6>
                <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                    {description === null ? "-" : description}
                </p>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="bg-white border-radius-24px padding-32px-all">

                {/* ===================Personal details ================*/}
                <div className="border-bottom-point5px border-color-mint-gray pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Profile information</h4>

                    <div className="row row-gap-24px">
                        <ProfileInfo title="Name"
                            description={props.data.overview.name} />
                        <ProfileInfo title="DOB"
                            description={props.data.overview.dob} />
                        <ProfileInfo title="PAN"
                            description={props.data.kyc.pan} />
                        <ProfileInfo title="Country"
                            description={props.data.kyc.country} />
                        <div className="col-lg-4 col-sm-6 col-12">
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                Email
                            </h6>
                            <a href={`mailto:${props.data.overview.email}`} className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0 text-decoration-none">
                                {props.data.overview.email === null ? "-" : props.data.overview.email}
                            </a>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                Phone no
                            </h6>
                            <a href={`tel:${props.data.overview.phone}`} className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0 text-decoration-none">
                                {props.data.overview.phone === null ? "-" : props.data.overview.phone}
                            </a>
                        </div>

                        <ProfileInfo title="Marital status"
                            description={props.data.overview.marital_status} />
                        <ProfileInfo title="Gender"
                            description={props.data.overview.gender} />


                    </div>
                </div>

                {/* ===================Tax details ================*/}
                <div className="border-bottom-point5px border-color-mint-gray mt-4 pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Nationality, tax and source</h4>

                    <div className="row row-gap-24px">
                        <ProfileInfo title="Source of income"
                            description={props.data.overview.wealth_source} />
                        <ProfileInfo title="Annual income"
                            description={props.data.overview.annual_income.toLocaleString('en-IN')} />
                        <ProfileInfo title="Occupation"
                            description={props.data.overview.occupation} />
                    </div>
                </div>

                {/* ===================Communication details ================*/}
                {Object.keys(props.data.kyc.address.permanent_address).length > 0 && <div className="border-bottom-point5px border-color-mint-gray mt-4 pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Communication details</h4>

                    <div className="row row-gap-24px">

                        {
                            Object.keys(props.data.kyc.address.permanent_address).length > 0 && <div className="col-lg-12 col-12 ">
                                <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                    Permanent address
                                </h6>
                                <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                                    {props.data.kyc.address.permanent_address.address_line1}  {props.data.kyc.address.permanent_address.address_line2}, {props.data.kyc.address.permanent_address.city} ,  {props.data.kyc.address.permanent_address.state} -  {props.data.kyc.address.permanent_address.pincode}
                                </p>
                            </div>
                        }

                        {
                            Object.keys(props.data.kyc.address.correspondence_address).length > 0 && <div className="col-lg-12 col-12 pt-4">
                                <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gray mb-2">
                                    Permanent address
                                </h6>
                                <p className="e-font-14 e-line-height-18 e-montserrat-medium color-dark-charcoal mb-0">
                                    {props.data.kyc.address.correspondence_address.address_line1} , {props.data.kyc.address.correspondence_address.address_line2}, {props.data.kyc.address.correspondence_address.city},  {props.data.kyc.address.correspondence_address.state} -  {props.data.kyc.address.correspondence_address.pincode}
                                </p>
                            </div>
                        }


                    </div>
                </div>}


                {/* ===================Bank details ================*/}
                <div className="mt-4 pb-4">
                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-3">Bank details</h4>

                    <div className="row row-gap-24px">
                        <ProfileInfo title="Account number"
                            description={props.data.bank.account} />

                        <ProfileInfo title="Bank name"
                            description={props.data.bank.name} />

                        <ProfileInfo title="Branch"
                            description={props.data.bank.branch} />

                        <ProfileInfo title="IFSC code"
                            description={props.data.bank.ifsc} />

                    </div>
                </div>

            </div>
        </Fragment >
    )
}

export default Profile