/*
 *   File : multi-select.js
 *   Author URI : www.evoqins.com
 *   Description : Multi select dropdown
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* packages */
import React, { Fragment, memo, useEffect, useState } from "react";
import { useRef } from "react";

// Custom components
import { Icon } from "../Icon";

// Custom styles 
import styles from "../../Styles/Components/search.module.scss";
import { useClickOutside } from "../../Helper/helper";
import { PrimaryButton } from "../Buttons";

const CustomMultiSelect = (props) => {
    const dropdownRef = useRef(false);
    const [value, setValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [selected, setSelected] = useState(props.selected);

    useEffect(() => {
        setSelected(props.selected)
    }, [props.selected])

    useClickOutside(dropdownRef, () => {
        setShowDropdown(false);
        setSelected(props.selected)
    })
    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    // onchange value
    const _handleChange = (event) => {
        const input_value = event.target.value;
        // Check if acceptNumber prop is true and the input is not a number
        if (props.acceptNumber && isNaN(input_value)) {
            return; // Do nothing if not a number
        }
        setValue(input_value);
        props.valueChange(input_value);
    }

    //on press of enter
    const _handleSearch = (event) => {
        const value = event.target.value;
        if (event.keyCode === 13) {
            props.handleSearch(value);
            setShowDropdown(true);
        }
    }

    function _handleShowDropdown() {
        props.onFocus();
        setShowDropdown(true)
    }

    function _handleSelectFund(item) {
        const selected_list = [...selected]
        const index = selected_list.findIndex((investor) => investor.value === item.value)
        if (index < 0) {
            selected_list.push(item);
        } else {
            selected_list.splice(index, 1);
        }
        setSelected(selected_list);
    }


    return (
        <Fragment>

            <div className={`${styles.e_search_select}  w-100 position-relative ${props.divClass}`}>
                {
                    value.length !== 0 ?
                        <>
                            <Icon icon="close"
                                size={16}
                                className="position-absolute top-0 bottom-0 right-16px my-auto cursor-pointer"
                                onClick={() => props.valueChange("")} />
                        </>
                        :
                        null
                }


                <input type="text"
                    id={props.id}
                    spellCheck="false"
                    placeholder={props.selected.length === 0 ? props.placeHolder : ""}
                    autoComplete="off"
                    readOnly={true}
                    onFocus={() => _handleShowDropdown()}
                    className={`${props.className}  border-light-gray color-dark-charcoal ps-3 cursor-pointer e-font-16 e-line-height-18`}
                    onChange={_handleChange}
                    onKeyDown={(e) => _handleSearch(e)} />

                {
                    props.selected.length > 0 &&
                    <div className="position-absolute top-12px left-16px truncate-first-line" onClick={_handleShowDropdown}>
                        {
                            props.selected.map((item, index) => {
                                return (
                                    <span className="e-montserrat-medium e-font-16 e-line-height-18 color-eerie-black cursor-pointer">{index > 0 ? " , " : ""} {item.label}</span>
                                )
                            })
                        }
                    </div>
                }

                {
                    <div className=' d-flex align-items-center h-10px w-max-content margin-6px-top'>
                        <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                            {props.error}
                        </p>
                    </div>
                }
                {
                    showDropdown &&
                    <Fragment>
                        <div className="position-absolute top-48px  e-box-shadow-white bg-white border-radius-8px bg-white w-100 z-index-10 overflow-hidden" ref={dropdownRef}>
                            <div className="h-200px overflow-y-scroll show-scrollbar">
                                {
                                    props.data.length > 0 ? props.data.map((item) => {
                                        return (
                                            <p className={`${styles.e_fund} bg-white color-dark-charcoal e-montserrat-medium e-font-14 e-line-height-18 letter-spacing-007em mb-0 py-2 px-3`}
                                                onClick={() => _handleSelectFund(item)}>
                                                <Icon icon={
                                                    selected.some((investor) => investor.value === item.value) ? "checked" : "unchecked"} size={16}
                                                    className='me-2' /> {item.label}
                                            </p>
                                        )
                                    })
                                        :
                                        <p className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-0 p-2">No funds found</p>
                                }
                            </div>
                            <div className="text-end pe-3 pb-2 bg-white ">
                                <PrimaryButton label="Apply" className="px-3 py-2 ms-auto"
                                    handleClick={() => {
                                        props.select(selected);
                                        setShowDropdown(false);
                                    }} />
                            </div>
                        </div>
                    </Fragment>
                }

            </div>

        </Fragment>
    );
};

CustomMultiSelect.defaultProps = {
    valueChange: () => { },
    id: 1,
    value: "",
    acceptNumber: false,
    placeholder: "Search",
    className: '',
    onClick: () => { },
    onFocus: () => { },
    handleSearch: () => { }
}

export default memo(CustomMultiSelect);