/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Notification list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useState } from "react";
import { toast } from "react-toastify";


// Custom components
import { CustomTitle } from "../../Components/Title";
import { CustomTextArea, CustomTextInput1 } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";

// Custom style

// Helper
import APIService from "../../Services/api-service";

const NotificationListing = () => {
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");

    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    function _handleTitle(input_value) {
        setTitle(input_value);
        setTitleError("");
    }

    function _handleMessage(input_value) {
        setMessage(input_value);
        setMessageError("");
    }

    function _handleSend() {
        let valid = true;
        if (title === "") {
            setTitleError("Title is required");
            valid = false
        }
        if (message === "") {
            setMessageError("Message is required");
            valid = false
        }
        if (valid === true) {
            _sendNotification();
        }
    }

    // API :: send notification
    const _sendNotification = () => {
        setApiLoader(true);
        const url = "/notification/send-global";
        const request = {
            "title": title,
            "message": message
        }
        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Notification sent successfully", {
                    type: "success"
                });
                setTitle("");
                setMessage("");
            } else {
                toast.error(response.message, {
                    type: "error"
                })
            }
            setApiLoader(false);
        });
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Manage Push Notifications" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb ">

                            <div className="padding-32px-lr">
                                <div className="row d-flex align-items-center ">
                                    <div className="col-xxl-9 col-xl-8 col-sm-6">
                                        <h4 className="mb-0 e-font-16 e-line-height-24  color-dark-charcoal e-montserrat-semi-bold">
                                            Push Notification
                                        </h4>
                                    </div>
                                    <div className="col-lg-6 pt-3">
                                        <CustomTextInput1
                                            label="Title"
                                            postfix="*"
                                            placeHolder="Enter title"
                                            className="mb-2"
                                            value={title}
                                            error={titleError}
                                            handleChange={_handleTitle} />
                                        <CustomTextArea label="Message"
                                            postfix="*"
                                            placeHolder="Enter Message"
                                            value={message}
                                            error={messageError}
                                            handleChange={_handleMessage} />
                                        <div className="col-6 pt-4">
                                            <PrimaryButton label="Send"
                                                className="w-100 py-3"
                                                disabled={apiLoader}
                                                loader={apiLoader}
                                                handleClick={_handleSend} />
                                        </div>
                                    </div>


                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    )
}
export default NotificationListing