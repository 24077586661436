/*
 *   File : all-notifications.js
 *   Author : https://evoqins.com
 *   Description : Notification list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { CustomTitle } from "../../Components/Title"
import { NotificationCard } from "../../Components/Cards"
import { EmptyScreen } from "../../Components/Others"
import { Icon } from "../../Components/Icon"

// Services
import APIService from "../../Services/api-service";

const NextIcon = () => {
    return (
        <Icon icon='pagination-next'
            width="24px"
            height="24px"
        />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='pagination-previous'
            width="24px"
            height="24px"

        />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}


const AllNotifications = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [pageLoader, setPageLoader] = useState(true);
    const [pageCount, setPageCount] = useState(0); //total number of pages


    const [currentPage, setCurrentPage] = useState(1);
    const [notificationList, setNotificationList] = useState([]);

    useEffect(() => {
        if (location.state === null) {
            _getReceivedNotifications(currentPage);
        }
        //eslint-disable-next-line
    }, [currentPage]);



    useEffect(() => {
        if (location.state !== null) {
            _getReceivedNotifications(location.state.page);
        }
        //eslint-disable-next-line
    }, []);

    function _navigateTarget(obj) {
        navigate("/all-notifications", {
            state: {
                page: currentPage
            }
        })
        let type = obj.type
        switch (type) {
            case 1:
                setTimeout(() => {
                    navigate("/customer/detail", {
                        state: {
                            id: obj.data_id,
                            tab_index: 1
                        }
                    })
                }, 0)
                break;
            case 2:
                setTimeout(() => {
                    navigate("/orders/detail", {
                        state: {
                            id: obj.data_id
                        }
                    })
                }, 0)
                break;

            default:
                break;
        }
    }

    // Handle page change
    function _handlePageChange(pageNumber) {
        window.scrollTo(0, 0)
        setCurrentPage(pageNumber + 1);
        _getReceivedNotifications(pageNumber + 1);
    };

    function _handleNavigate(obj, index) {
        _navigateTarget(obj)

    }

    // API - get received notifications
    const _getReceivedNotifications = (page) => {
        setCurrentPage(page);
        const url = "/notification/get-received-notification";

        const request = {
            page_num: page
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setNotificationList(response.data);
                setPageCount(0);
            }
            setPageLoader(false);
        })
    }




    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Notification" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="bg-white border-radius-32px padding-40px-tb padding-32px-lr">
                            <div className="d-flex justify-content-between align-items-center ">
                                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-dark-charcoal">
                                    All notifications
                                </h4>

                            </div>
                            <div className="row margin-32px-top">
                                <div className="col-11">
                                    {
                                        pageLoader === true ?
                                            <div className="h-80vh d-flex align-items-center justify-content-center">
                                                <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                                    alt={"loader"}
                                                    width={60}
                                                    height={60}
                                                />
                                            </div>
                                            :
                                            notificationList.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No notification found"
                                                    image={require("../../Assets/Images/Empty/no-data.png")}
                                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                                :
                                                <Fragment>
                                                    {notificationList.map((item, index) => {
                                                        return (
                                                            <NotificationCard data={item}
                                                                key={index}
                                                                readNotification={() => _handleNavigate(item, index)} />
                                                        )
                                                    })}
                                                    <ReactPaginate previousLabel={<PrevIcon />}
                                                        nextLabel={<NextIcon />}
                                                        breakLabel={<BreakLabel />}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(value) => _handlePageChange(value.selected)}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                        containerClassName="e-pagination mt-5"
                                                        activeClassName="e-pagination-active" />
                                                </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AllNotifications