/*
 *   File : area.js
 *   Author URI : https://evoqins.com
 *   Description : Area chart to show portfolio returns
 *   Integrations : chart.js
 *   Version : v1.1
 */
import { Fragment, useEffect, useRef } from "react";
import Chart from "chart.js/auto";

import Colors from '../../Styles/color.module.scss';

const AreaChart = (props) => {

    const chartRef = useRef(null);
    // const [chart, setChart] = useState(null);
    // const [focusPoint, setFocusPoint] = useState(null);

    const _getGradient = (ctx, chartArea) => {
        if (chartArea) {
            let width, height, gradient;
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (gradient === null || width !== chartWidth || height !== chartHeight) {
                width = chartWidth;
                height = chartHeight;
                gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0, '#7a9ec3');
                gradient.addColorStop(1, '#b0d2f2');
            }
            return gradient;
        }
    };


    useEffect(() => {
        if (props.labels !== undefined) {
            var chartContext = document.getElementById('areaChart').getContext('2d');
            chartContext.canvas.style.zIndex = 1;


            const chartInstance = new Chart(chartContext, {
                type: 'line',
                data: { //chart area configuration
                    labels: props.labels,
                    datasets: [{
                        data: props.data,
                        order: 1,
                        backgroundColor: function (context) {
                            const chart = context.chart;
                            const { ctx, chartArea } = chart;

                            return _getGradient(ctx, chartArea);
                        },
                        borderColor: [
                            Colors.navy_blue
                        ],
                        borderWidth: 1,
                        tension: 0,
                        fill: 'start',
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        pointBorderWidth: 0,
                        pointHoverBorderWidth: 0,
                        pointHoverBorderColor: Colors.white,
                        pointBorderColor: Colors.white,
                        pointBackgroundColor: Colors.navy_blue,
                        padding: 3,
                    }
                    ]
                },

                options: {
                    indexAxis: 'x',
                    scales: {
                        x: {
                            beginAtZero: false,
                            offset: false,
                            border: {
                                display: true,
                            },
                            ticks: {
                                display: true,
                            },
                            grid: {
                                display: false
                            },
                        },
                        y: {
                            beginAtZero: false,
                            offset: false,
                            ticks: {
                                display: true,
                                maxTicksLimit: 5,
                                stepSize: 2,
                            },
                            border: {
                                display: true,
                            },
                            grid: {
                                borderColor: Colors.anti_flash_white,
                            },
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    plugins: {
                        tooltip: {
                            enabled: true,
                            backgroundColor: Colors.white,
                            borderColor: Colors.pale_gray,
                            borderWidth: 1,
                            titleFont: {
                                size: 0,
                            },
                            bodyFont: {
                                family: "Montserrat-Medium",
                                size: 12,
                            },
                            footerColor: Colors.dark_silver,
                            bodyColor: Colors.black,
                            callbacks: {
                                title: () => '',
                                label: (context) => {
                                    const value = context.raw;
                                    return `AUM: ₹${value.toLocaleString("en-IN")} (${context.label})`
                                },
                            },
                            position: 'nearest', // Position tooltip closer to the active point
                            caretSize: 6,
                            caretPadding: 10,
                            cornerRadius: 4,
                            padding: 10,
                            boxPadding: 4,
                            usePointStyle: true,
                        },
                        legend: {
                            display: false,
                        },
                    }
                },
                plugins: [{
                    id: 'mouseLine',
                    afterDatasetsDraw: (chart) => {
                        if (chart.tooltip._active && chart.tooltip._active.length && chart.tooltip) {

                            const ctx = chart.ctx;
                            const activePoint = chart.tooltip._active[0];
                            const x = activePoint.element.x;
                            const y = activePoint.element.y;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            chartRef.current = activePoint;

                            // Draw vertical dotted line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, bottomY);
                            ctx.lineTo(x, topY);
                            ctx.setLineDash([0, 0]); // Dotted line style
                            ctx.lineWidth = 1.5;
                            ctx.strokeStyle = Colors.navy_blue;
                            ctx.stroke();
                            ctx.restore();

                            // Draw the pointer (circle) at the active point
                            ctx.save();
                            ctx.beginPath();
                            ctx.arc(x, y, 7, 0, 2 * Math.PI); // Pointer size
                            ctx.fillStyle = Colors.navy_blue; // Pointer color
                            ctx.fill();
                            ctx.lineWidth = 2;
                            ctx.strokeStyle = Colors.white; // Pointer border color
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                    beforeRender: (chart) => {
                        const { tooltip, data } = chart;

                        // If there's no data, exit
                        if (!data.datasets.length) {
                            return;
                        }
                        // Ensure chartRef and the element exist
                        if ((!tooltip._active || !tooltip._active.length)) {
                            setTimeout(() => {
                                if (chartRef.current && chartRef.current.element && chartRef.current.element.x) {
                                    chart.tooltip.setActiveElements(
                                        [{ datasetIndex: chartRef.current.datasetIndex, index: chartRef.current.index }],
                                        { x: chartRef.current.element.x, y: chartRef.current.element.y }
                                    );
                                    chart.update(); // Update the chart to show the tooltip
                                }
                            }, 100)
                        }

                    },

                    afterRender: (chart) => {
                        // Get the first dataset
                        const { tooltip, data } = chart;

                        // If there's no data, exit
                        if (!data.datasets.length) {
                            return;
                        }

                        // Show tooltip at the first point if not visible
                        if ((!tooltip._active || !tooltip._active.length)) {
                            if (chartRef.current) {
                                chart.tooltip.setActiveElements(
                                    [{ datasetIndex: chartRef.current.datasetIndex, index: chartRef.current.index }],
                                    { x: chartRef.current.element.x, y: chartRef.current.element.y }
                                );
                                chart.update(); // Update the chart to show the tooltip
                            }
                        }
                    },
                }]
            });
            // Function to show tooltip on the last point
            function showTooltipOnLastPoint() {
                const chart = chartInstance;


                const lastIndex = chart.data.labels.length - 1; // Last index of data
                const meta = chart.getDatasetMeta(0); // Get metadata for the dataset
                const lastPoint = meta.data[lastIndex]; // Get the last point

                chart.tooltip.setActiveElements(
                    [{ datasetIndex: 0, index: lastIndex }], // Set active element to the last point
                    { x: lastPoint.x, y: lastPoint.y, caretX: lastPoint.x, caretY: lastPoint.y } // Position for the tooltip
                );

                chart.update(); // Update the chart to show the tooltip
            }


            showTooltipOnLastPoint();

            return () => {
                chartInstance.destroy();
            };
        }
        // eslint-disable-next-line
    }, [props.data]);

    return (
        <Fragment>
            <div className="position-relative" id='areaChartContainer'>
                <canvas id="areaChart" className="position-relative" height={window.innerWidth <= 576 ? "290" : "200"} width="400" style={{ width: "100%", height: "100%" }}></canvas>
                {
                    props.data.length === 0 &&
                    <div className="e-goal-chart-height d-flex justify-content-center align-items-center color-chinese-black e-montserrat-regular e-font-16 e-line-height-22 position-absolute top-0 end-0 bottom-0 start-0 m-auto">
                        No data found
                    </div>
                }
            </div>

        </Fragment>
    );
};

export default AreaChart;

AreaChart.defaultProps = {
    labels: [],
    data: []
};
