/*
 *   File : notification.js
 *   Author : https://evoqins.com
 *   Description : Dashboard Summary Card component
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

import { Fragment, memo } from "react"

const NotificationCard = (props) => {

    return (
        <Fragment>
            <div className={`bg-white e-box-shadow-white border-radius-28px py-3 px-4 mb-3 cursor-pointer`} onClick={props.readNotification}>
                <div className="d-flex align-items-center gap-22px">
                    {/* <div className={`${props.data.is_read === false ? "e-bg-navy-blue" : "e-bg-medium-gray"} w-16px h-16px border-radius-100`}></div> */}
                    <span className="e-font-14 e-lin-height-20 e-montserrat-semi-bold color-dark-charcoal">{props.data.title}</span>
                </div>
                <p className="e-font-14 e-line-height-24 e-montserrat-medium mb-0">{props.data.description}</p>

                {/* <div className="margin-22px-tb">
                    {
                        props.data.data.fund_detail.map((item) => {
                            return (
                                <p className="e-font-14 e-line-height-24 e-montserrat-medium mb-1">{item.label} - {item.value}</p>
                            )
                        })
                    }
                    {
                        props.data.data.order_detail.map((item) => {
                            return (
                                <p className="e-font-14 e-line-height-24 e-montserrat-medium mb-1">{item.label} - {item.value}</p>
                            )
                        })
                    }
                    {
                        props.data.data.user_detail.map((item) => {
                            return (
                                <p className="e-font-14 e-line-height-24 e-montserrat-medium mb-1">{item.label} - {item.value}</p>
                            )
                        })
                    }
                </div> */}

                <span className="e-font-12 e-montserrat-medium e-line-height-16 color-medium-gray">{props.data.created}</span>

            </div>
        </Fragment>
    )
}

export default memo(NotificationCard)
