/*
 *   File : manage-banner.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for creating or updating banner.
 *   Integrations : null
 *   Version : v1.1
 */


import { Fragment, memo, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

// Custom components
import { Icon } from "../Icon";
import { CustomFileInput, CustomSelect, CustomTextInput1 } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

// Services
import APIService from "../../Services/api-service";
import { _uploadDocumentToS3 } from "../../Services/aws-service";
import { _getFilePath } from "../../Helper/api";

const CONTENT_TYPES = [
    {
        label: "Video",
        value: 1
    },
    {
        label: "Blog",
        value: 2
    },
    {
        label: "Default",
        value: 3
    }
]

const BANNER_TYPES = [
    {
        label: "Banner 1",
        value: 1
    },
    {
        label: "Banner 2",
        value: 2
    },
    {
        label: "Banner 3",
        value: 3
    },
    {
        label: "Banner 4",
        value: 4
    }
]

const ManageBannerModal = (props) => {

    const bannerRef = useRef(null);

    const [bannerType, setBannerType] = useState(null);
    const [bannerTypeError, setBannerTypeError] = useState("");

    const [contentType, setContentType] = useState(null);
    const [contentTypeError, setContentTypeError] = useState("");

    const [bannerImage, setBannerImage] = useState("");
    const [bannerLoader, setBannerLoader] = useState(false);
    const [bannerImageError, setBannerImageError] = useState("");


    const [redirectionUrl, setRedirectionUrl] = useState("");
    const [redirectionUrlError, setRedirectionUrlError] = useState("");


    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("manage-banner");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            _clearFields();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {

        if (props.banner !== null) {
            const index = CONTENT_TYPES.findIndex((item) => item.value === props.banner.banner_id);
            setBannerType(BANNER_TYPES[index])

            const content_type_index = CONTENT_TYPES.findIndex((item) => item.value === props.banner.content_type);
            setContentType(CONTENT_TYPES[content_type_index])
            setBannerImage(props.banner.image_url);
            setRedirectionUrl(props.banner.redirection_url);

        }

        //eslint-disable-next-line
    }, [props.banner])


    function _handleBannerType(select_value) {
        setBannerType(select_value);
        setBannerTypeError("");
    }

    function _handleContentType(select_value) {
        setContentType(select_value);
        setContentTypeError("");
    }

    async function _handleWebFileUpload(file) {
        setBannerLoader(true);
        setBannerImageError("");

        const request = {
            file: file.name,
            is_public: true
        }

        await _getFilePath(request).then(async (response) => {
            toast.dismiss();
            await _uploadDocumentToS3(response.data, file).then((result) => {
                setTimeout(() => {
                    setBannerImage(response.data.view_info);
                }, 100)
                setBannerLoader(false);
            }).catch((error) => {
                toast.error(error, {
                    type: "error"
                });
            });

        });
    }

    function _handleRedirectionUrl(input_value) {
        setRedirectionUrl(input_value);
        setRedirectionUrlError("");
    }

    function _clearFields() {
        setContentType(null);
        setContentTypeError(null);
        setBannerType(null);
        setBannerTypeError(null);

        setBannerImage("");
        setBannerImageError("");

        setRedirectionUrl("");
        setRedirectionUrlError("");

    }

    function _handleSubmitForm() {
        let valid = true;
        if (bannerType === null) {
            valid = false;
            setBannerTypeError("Banner type is required");
        }
        if (contentType === null) {
            valid = false;
            setContentTypeError("Content type is required");
        }
        if (bannerImage === "") {
            valid = false;
            setBannerImageError("Banner image is required");
            bannerRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }

        if (valid === true) {
            _manageBanner();
        }
    }

    function _handleSizeError(type) {
        switch (type) {
            case 1: setBannerImageError("File size exceeds 2mb");
                break;
            default:
                break
        }
    }



    // API - add/update banner
    const _manageBanner = () => {
        setApiLoader(true);
        const url = "/banner/upsert";
        const request = {
            "banner_id": bannerType.value,
            "image_url": bannerImage,
            "redirection_url": redirectionUrl,
            "content_type": contentType.value,
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Banner updated", {
                    type: 'success'
                });
                _clearFields();
                props.bannerUpdated()
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false)
        });
    }


    return (
        <Fragment>
            <div className={`modal fade ${style.e_notification_modal}`}
                id="manage-banner"
                tabIndex="-1"
                aria-labelledby="manage-banner"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-24px-lr padding-32px-tb ${style.e_content} `}>

                        <div className="d-flex justify-content-between align-items-center mb-4 px-2">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                {props.banner !== null ? "Edit banner image" : "Add banner image"}
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className={`${style.e_modal_fields} px-2 pb-2`}>
                            <div className="row padding-32px-top">
                                <div className="col-6 mb-2">
                                    <CustomSelect type={3}
                                        postFix={true}
                                        placeholder="Banner type"
                                        label="Banner type"
                                        error={bannerTypeError}
                                        value={bannerType}
                                        options={BANNER_TYPES}
                                        selectChange={_handleBannerType} />
                                </div>
                                <div className="col-6 mb-2">
                                    <CustomSelect type={3}
                                        postFix={true}
                                        placeholder="Content type"
                                        label="Content type"
                                        error={contentTypeError}
                                        value={contentType}
                                        options={CONTENT_TYPES}
                                        selectChange={_handleContentType} />
                                </div>

                                <div className="col-12 mb-2" ref={bannerRef}>
                                    <CustomFileInput shape="rectangle"
                                        label="Upload image"
                                        postfix={true}
                                        file={bannerImage}
                                        loader={bannerLoader}
                                        accept={["JPG", "PNG", "JPEG", "GIF"]}
                                        error={bannerImageError}
                                        sizeError={() => _handleSizeError(1)}
                                        onSelectFile={_handleWebFileUpload}
                                        clearFile={() => setBannerImage("")} />
                                </div>
                                <div className="col-12 mb-2">
                                    <CustomTextInput1 label="Redirection url"
                                        placeHolder="https://finozone.in"
                                        type="text"
                                        inputClass="e-redirection-input"
                                        error={redirectionUrlError}
                                        value={redirectionUrl}
                                        handleChange={_handleRedirectionUrl} />
                                </div>


                            </div>
                        </div>

                        <div className="d-flex gap-8px justify-content-end padding-40px-top px-2">
                            <SecondaryButton label="Cancel"
                                cancel="modal"
                                className="padding-14px-tb padding-50px-lr" />
                            <PrimaryButton label={props.banner !== null ? "Update" : "Add"}
                                className="padding-14px-tb padding-50px-lr"
                                loader={apiLoader}
                                disabled={apiLoader}
                                handleClick={_handleSubmitForm} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default memo(ManageBannerModal);