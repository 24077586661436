/*
 *   File : toggle.js
 *   Author : https://evoqins.com
 *   Description : Toggle component
 *   Version : 1.0.0
*/

/* import packages */
import React, { useEffect, useState } from 'react';

/* import styles */
import Color from "../../Styles/color.module.scss"
import style from '../../Styles/Components/toggle.module.scss'

const Toggle = React.memo((props) => {
    const [leftPosition, setLeftPosition] = useState(props.check);

    useEffect(() => {
        setLeftPosition(props.check)
    }, [props.check])

    const _handleClick = () => {
        props.onChange(leftPosition);
    }

    return (
        <div className={`border-radius-16px position-relative z-index-1 cursor-pointer ${style.e_switch}  ${props.className}`}
            style={{
                backgroundColor: leftPosition === false ?
                    Color.light_gray
                    :

                    Color.navy_blue
            }}
            onClick={_handleClick}>
            <div className={`bg-white border-radius-100 position-absolute cursor-pointer z-index-1 ${style.e_head} `}
                style={{ left: leftPosition === false ? '0.15rem' : '52%' }}
            // onClick={_handleClick}
            ></div>
        </div>
    )
})

Toggle.defaultProps = {
    onChange: () => { }
}
export default Toggle;