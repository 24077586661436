/*
 *   File : pin-input.js
 *   Author : https://evoqins.com
 *   Description : component used in the platform for submitting PIN in the platform
 *   Integrations : null
 *   Version : 1
*/

import React, { useEffect, useState, memo, useRef, Fragment } from "react";

import style from "../../Styles/Components/pin.module.scss"

const CustomPINInput = React.memo((props) => {

    const otpRef = useRef(null);

    const [pinString, setPinString] = useState(props.pinString);
    const [pin, setPin] = useState([]);
    const [error, setError] = useState("");
    const [focusedInput, setFocusedInput] = useState(false);


    useEffect(() => {
        setPinString(props.pinString);
        const characters = [];

        for (let i = 0; i < props.pinString.length; i++) {
            characters.push(props.pinString.charAt(i));
        }
        setPin(characters);
        if (props.pinString.length === 0) {
            setPin([]);
        }

        // code to update the initial focus
        if (props.autoFocus === true) {
            setTimeout(() => {
                otpRef?.current?.focus();
            }, 500);
        }
        //eslint-disable-next-line
    }, [props.pinString, props.autoFocus]);

    useEffect(() => {
        if (props.disabled === true) {
            setFocusedInput(false);
        }
    }, [props.disabled])

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    const _handleInput = (value) => {
        let input_field = value.target.value;
        if (input_field.length <= (props.type === 1 ? 4 : 6)) {
            setPinString(input_field);
            const characters = [];

            for (let i = 0; i < input_field.length; i++) {
                characters.push(input_field.charAt(i));
            }
            setPin(characters);
            props.handleChange(input_field);
        }
    };

    const _validateNumber = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
        const is_enter_key = key_code === 13;
        const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i)];

        if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
            return null;
        }

        if (!allowed_key_codes.includes(key_code) && !is_enter_key) {
            e.preventDefault();
        }

        if (e.keyCode === 13) {
            props.onSubmit();
        }
    };

    return (
        <div className={`position-relative w-100 ${style.e_pin} ${props.className}`}>
            {
                props.title &&
                <p className={`color-black e-montserrat-medium e-font-14 e-line-height-22  margin-6px-bottom`}>{props.title}
                    <span className='color-deep-carmine-pink'>
                        {props.postfix}
                    </span> </p>
            }

            <input type='text'
                ref={otpRef}
                id={props.id}
                value={pinString}
                spellCheck="false"
                autoComplete="off"
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                className='mb-0 color-white w-100'
                onFocus={() => setFocusedInput(true)}
                onBlur={() => setFocusedInput(false)}
                onKeyDown={_validateNumber}
                onChange={_handleInput} />

            <label className={`d-flex gap-16px position-absolute  left-0 ${!props.title && "top-0"} `} htmlFor={props.id}   >
                <div className={`border-radius-12px  border-point5px d-flex justify-content-center align-items-center cursor-pointer position-relative ${focusedInput === true ? `${pin.length === 0 ? style.e_focused : ''} border-navy-blue` : 'border-light-gray'}`}>
                    <span className="color-black e-montserrat-regular e-font-38 top--8px position-absolute">{pin.length !== 0 ? "\u2022" : ""}</span>
                </div>
                <div className={`border-radius-12px  border-point5px d-flex justify-content-center align-items-center cursor-pointer position-relative ${focusedInput === true && pin.length >= 1 ? `${pin.length === 1 ? style.e_focused : ''} border-navy-blue` : 'border-light-gray'}`}>
                    <span className="color-black e-montserrat-regular e-font-38 top--8px position-absolute">{pin.length >= 2 ? "\u2022" : ""}</span>
                </div>
                <div className={`border-radius-12px  border-point5px d-flex justify-content-center align-items-center cursor-pointer position-relative ${focusedInput === true && pin.length >= 2 ? `${pin.length === 2 ? style.e_focused : ''} border-navy-blue` : 'border-light-gray'}`}>
                    <span className="color-black e-montserrat-regular e-font-38 top--8px position-absolute">{pin.length >= 3 ? "\u2022" : ""}</span>
                </div>
                <div className={`border-radius-12px  border-point5px d-flex justify-content-center align-items-center cursor-pointer position-relative ${focusedInput === true && pin.length >= 3 ? `${pin.length === 3 ? style.e_focused : ''} border-navy-blue` : 'border-light-gray'}`}>
                    <span className="color-black e-montserrat-regular e-font-38 top--8px position-absolute">{pin.length >= 4 ? "\u2022" : ""}</span>
                </div>
                {
                    props.type === 2 &&
                    <Fragment>
                        <div className={`color-black e-montserrat-regular e-font-16 e-line-height-20 border-radius-12px  border-point5px d-flex justify-content-center align-items-center cursor-pointer ${focusedInput === true && pin.length >= 4 ? 'border-navy-blue' : 'border-silver-sand'}`}>
                            {pin.length >= 5 ? "\u2022" : ""}
                        </div>
                        <div className={`color-black e-montserrat-regular e-font-16 e-line-height-20 border-radius-12px  border-point5px d-flex justify-content-center align-items-center cursor-pointer ${focusedInput === true && pin.length >= 5 ? 'border-navy-blue' : 'border-silver-sand'}`}>
                            {pin.length >= 6 ? "\u2022" : ""}
                        </div>
                    </Fragment>
                }
            </label>
            {
                error &&
                <div className='position-absolute d-flex align-items-center margin-6px-top bottom--22px'>
                    <p className='color-deep-carmine-pink  e-montserrat-medium e-font-14  e-line-height-20 mb-0 '>
                        {error}
                    </p>
                </div>
            }
        </div>
    )
})

export default memo(CustomPINInput);

CustomPINInput.defaultProps = {
    pinString: '',
    autoFocus: false,
    disabled: false,
    type: 1,
    onSubmit: () => { }
}