/*
 *   File : detail.js
 *   Author : https://evoqins.com
 *   Description : Customer Detail
 *   Version : 1.0.0
*/


// packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";

// Custom components
import { Breadcrumb, EmptyScreen } from "../../Components/Others";
import { Badge } from "../../Components/Badge";
import { DataTableContainer } from "../../Components/DataTable";
import { OrderProgressModal } from "../../Components/Modal";

// Custom styles
import Colors from '../../Styles/color.module.scss';

// Service
import APIService from "../../Services/api-service";
import Config from "../../Helper/config";


// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
            '&:hover': {
                backgroundColor: Colors.lavender_mist
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const OrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showOrderDetail, setShowOrderDetail] = useState(null);

    const [orderDetail, setOrderDetail] = useState([]);
    const [apiLoader, setApiLoader] = useState(true);



    useEffect(() => {
        if (showOrderDetail !== null) {
            const modal = new Modal(document.getElementById("order-progress"), {});
            modal.show();
        }
    }, [showOrderDetail]);

    useEffect(() => {
        if (location.state !== null) {
            _getOrderDetail(location.state.id);
        }
    }, [location]);

    function _handleNavigate() {
        navigate("/orders", {
            state: {
                page: location.state.page,
                selectedPaymentStatus: location.state.selectedPaymentStatus,
                selectedOrderStatus: location.state.selectedOrderStatus,
                selectedOrderType: location.state.selectedOrderType,
                selectedPaymentType: location.state.selectedPaymentType,
                selectedMilestone: location.state.selectedMilestone,
                fromDate: location.state.fromDate,
                endDate: location.state.endDate,
                search: location.state.search
            }
        })
    }

    function _handleOrderDetailModal(row) {
        setShowOrderDetail(row);
    }

    // API - order detail
    const _getOrderDetail = (id) => {
        const url = "/order/detail";
        const request = {
            order_id: id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setOrderDetail(response.data);
            }
            setApiLoader(false);
        })
    }

    const ORDER_COLUMNS = [
        {
            name: "Master ORDER ID.",
            cell: row => row.master_order_id === null ? "-" : row.master_order_id,
            width: "250px",
        },
        {
            name: "Fund",
            cell: row => <div className="d-flex align-items-center" onClick={() => _handleOrderDetailModal(row)}>

                <span>{row.mutual_fund}</span>
            </div >,
            width: "300px"
        },

        {
            name: "Amount",
            cell: row => row.amount,
            width: "120px"
        },
        {
            name: "Transaction ID.",
            cell: row => row.transaction_id === null ? "-" : row.transaction_id,
            width: "250px",
        },
        {
            name: "Transaction date",
            width: "170px",
            cell: row => row.transaction_date
        },
        {
            name: "Units",
            width: "100px",
            cell: row => row.units ? row.units.toFixed(2) : "-"
        },
        {
            name: "Type",
            width: "100px",
            cell: row => row.type ? row.type : "-"
        },
        {
            name: "Folio",
            cell: row => row.folio_number ? row.folio_number : "-",
            width: "130px",
        },


        {
            name: "Status",
            width: "140px",
            cell: row => <Badge status={row.status}
                color={row.status === "Success" ? Config.BADGE_COLORS.green :
                    row.status === "Failed" ? Config.BADGE_COLORS.red :
                        row.status === "Processing" ? Config.BADGE_COLORS.orange :
                            Config.BADGE_COLORS.blue} />
        },

    ]
    return (
        <Fragment>
            {
                apiLoader === true ?
                    <div className="h-80vh d-flex align-items-center justify-content-center">
                        <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    Object.keys(orderDetail).length > 0 ?
                        <Fragment>
                            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                                <div className="padding-110px-bottom">
                                    <Breadcrumb handleNavigate={_handleNavigate}
                                        mainTitle="Order summary"
                                        subTitle="Order detail" />
                                </div>
                            </div>
                            <div className="e-body-wrapper">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="position-relative bg-white border-radius-24px py-4 mt-4">
                                            <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4 padding-32px-lr">
                                                Order details
                                            </h4>
                                            <DataTableContainer data={orderDetail}
                                                columns={ORDER_COLUMNS}
                                                customStyles={TABLE_STYLE}
                                                rowClick={(row) => _handleOrderDetailModal(row)}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        :
                        <EmptyScreen className="my-5"
                            title="No order detail found"
                            image={require("../../Assets/Images/Empty/no-data.png")}
                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
            }
            {
                showOrderDetail !== null && <OrderProgressModal
                    data={showOrderDetail}
                    type={orderDetail.summary.type}
                    closeModal={() => _handleOrderDetailModal(null)} />
            }
        </Fragment>
    )
}

export default OrderDetail