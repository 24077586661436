
const initialState = {
    name: "",
    toggle: true,
    pageNumber: null,
    IS_LOGGED_IN: sessionStorage.getItem("finozone_access_token") ? true : false,
    NOTIFICATION_COUNT: 0,
};

export default function adminReducer(state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_SIDEBAR":
            return {
                ...state,
                toggle: action.payload
            };
        case "LOGIN_STATUS":
            return {
                ...state,
                IS_LOGGED_IN: action.payload
            };
        case "NOTIFICATION_COUNT":
            return {
                ...state,
                NOTIFICATION_COUNT: action.payload
            };
        default:
            return state
    }
}