/*
 *   File : custom-textarea.js
 *   Author : https://evoqins.com
 *   Description : Textarea component
 *   Integrations : null
 *   Version : 1.0.0
*/

import React from 'react';
import style from '../../Styles/Components/custom-textbox.module.scss';

const CustomTextArea = React.memo((props) => {

    return (
        <div className={props.error && props.error.length !== 0 ? `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` : `${props.className} ${style.e_input_wrapper} position-relative w-100`}>
            <p className={`${props.labelClass} e-montserrat-medium color-eerie-black e-font-14 e-line-height-18 cursor-text margin-6px-bottom`}
                htmlFor={props.label}>
                {props.label}
                <span className='color-deep-carmine-pink'>
                    {props.postfix}
                </span>
            </p>
            {/* Text area */}
            <textarea value={props.value}
                placeholder={props.placeHolder}
                id={props.id}
                type='text'
                disabled={props.disabled}
                tabIndex="-1"
                className={`${style.e_textarea} color-charleston-green e-montserrat-medium e-font-16 e-line-height-24 w-100 no-outline `}
                onChange={(e) => props.handleChange(e.target.value)}
                required
                rows={6}>
            </textarea>
            {/* Label */}

            {/* Error text */}
            {
                <div className='h-10px d-flex align-items-center w-max-content'>
                    {/* <Icon icon="info-circle"
                        size={16}
                        className="me-1" /> */}
                    <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                        {props.error}
                    </p>
                </div>
            }
        </div>
    )
})

CustomTextArea.defaultProps = {
    value: null,
    handleChange: () => { },
    className: '',
    error: '',
    placeHolder: ''
};

export default CustomTextArea;